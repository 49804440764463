
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.sortBySelect {
  :global(.MuiInputBase-root) {
    margin-top: 0.75rem;
  }
}
