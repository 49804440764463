
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.searchInput {
  // background: $white;
  border-radius: 0.5rem;

  &__transparent {
    background-color: #ffffff20;

    :global(.MuiInputBase-inputTypeSearch) {
      color: palette.$white;
    }

    :global(.MuiSvgIcon-root) {
      color: palette.$white;
    }
  }

  &__dark {
    background: palette.$primary-lighter;
    :global(.MuiSvgIcon-root) {
      color: palette.$primary-main;
    }
  }
}
