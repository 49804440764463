// -- Spacing
$row-spacing: 1.25rem;
$column-spacing: 2.5rem;
$top-nav-height: 4rem;
$bottom-footer-height: 4rem;
$space-image-height: 15rem;
$space-image-width: 16rem;
$base-grid: 0.25rem;
$desktop-cutoff: 48em;

// -- Button Sizes
$button-large-height: 2.625rem; // 42px
$button-medium-height: 2.25rem; // 36px
$button-small-height: 1.875rem; // 30px

// -- Z-Indexes
$base-z-index: 1;
$react-sticky-index: 2;
$drop-down-index: $react-sticky-index + 1;
$z-index-medium: 40;
$top-nav-z-index: 1101;
$intercom-z-index: 1102; // more than top-nav z-index and less than MUI drawer z-index. MUI drawer z-index is 1200.

// -- Text Sizes
$font-size-h1: 3.75rem;
$font-size-h2: 3rem;
$font-size-h3: 2.125rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;
$font-size-body-1: 0.875rem;
$font-size-body-2: 0.75rem;
$font-size-shrunken-input-label: 1rem;
$font-size-subtitle-1: $font-size-body-1;
$font-size-subtitle-2: $font-size-body-2;
$font-size-caption: $font-size-body-2;
$font-size-overline: $font-size-body-2;

// -- Dropdown Menu
$dropdown-menu-width: 16.563rem;

// -- Drawer Sizes
$xLarge-drawer-width: 85%;
$large-drawer-width: 78.313rem;
$medium-drawer-width: calc($large-drawer-width - 10rem);
$small-drawer-width: 39.125rem;

// -- Modal Sizes
$small-modal-width: 39.125rem;
$large-modal-width: 43.75rem;

// -- Side Nav
$docked-side-nav-width: 5.25rem;
$open-side-nav-width: 15rem;
$side-nav-footer-bottom-margin: 2.5rem;
$side-nav-header-main-height: 6.25rem;
$side-nav-header-settings-height: 3.75rem;

// -- Borders
$border-sm-width: 1px;
$border-sm-solid-light: $border-sm-width solid rgba(0, 0, 0, 0.23);

$xxs-sm-padding: calc(0.5 * #{$base-grid}); // 2px or 0.125rem
$xs-sm-padding: calc(1 * #{$base-grid}); //4px or 0.25rem
$sm-padding: calc(2 * #{$base-grid}); //8px or 0.5rem
$sm-10-padding: calc(2.5 * #{$base-grid}); //10px or 0.625rem
$sm-12-padding: calc(3 * #{$base-grid}); //12px or 0.75rem
$md-padding: calc(4 * #{$base-grid}); //16px or 1rem
$lg-padding: calc(5 * #{$base-grid}); //20px or 1.25rem
$xl-padding: calc(6 * #{$base-grid}); //24px or 1.5rem
$xxl-padding: calc(8 * #{$base-grid}); //32px or 2rem
$xxxl-padding: calc(10 * #{$base-grid}); //40px or 2.5rem
$xl-12-padding: calc(12 * #{$base-grid}); //48px or 3rem
$xl-13-padding: calc(13 * #{$base-grid}); //52px or 3.25rem
$xl-18-padding: calc(18 * #{$base-grid}); //72px or 4.5rem
$xl-30-padding: calc(30 * #{$base-grid}); //120px or 7.5rem

$data-table-padding-default: $lg-padding;
$data-table-padding-sm: $sm-padding;
