
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.root {
  align-items: flex-start;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.avatar {
  margin-right: 0.5rem;
}
