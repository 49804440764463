
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.textFilter {
  :global(.MuiFormControl-root) {
    width: 100%;
  }
}
