
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.globalSearch {
  overflow-y: auto;
  max-height: variables.$desktop-cutoff;

  &__results {
    padding: variables.$sm-12-padding 0;

    :global(.MuiTypography-subtitle2) {
      padding-left: variables.$sm-12-padding;
    }

    :global(.MuiMenuItem-root) {
      padding: variables.$sm-12-padding;
      white-space: pre-wrap;
    }
  }
}
