
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.semiCollapsible {
  &AccordionRoot {
    padding: variables.$lg-padding;
    border-radius: variables.$xs-sm-padding;
    overflow: hidden;

    &:last-of-type {
      margin-bottom: variables.$md-padding;
    }

    :global(.MuiCollapse-root) {
      visibility: visible; // should be always visible
      height: fit-content !important; // have to use !important to override MUI's inline style
      min-height: calc(
        3 * #{variables.$lg-padding}
      ) !important; // have to use !important to override MUI's inline style
      max-height: calc(10 * #{variables.$lg-padding});
    }

    &:global(.Mui-expanded) {
      .semiCollapsibleAccordionDetailsOverlay {
        display: none;
      }
    }
  }

  &AccordionExpanded {
    :global(.MuiCollapse-root) {
      max-height: unset;
    }
  }

  &AccordionSummaryRoot {
    flex-direction: row-reverse;
    align-items: start;
    column-gap: 0.5rem;
    padding: 0;
    margin: 0;
    min-height: unset;

    &:global(.Mui-expanded) {
      min-height: unset;
      margin: 0;
    }
  }

  &AccordionSummaryContent {
    margin: 0;
    justify-content: space-between;

    &:global(.Mui-expanded) {
      margin: 0;
    }
  }

  &AccordionDetailsOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: variables.$z-index-medium;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(
      to bottom,
      rgba(palette.$white, 0.08),
      rgba(palette.$white, 1)
    );
  }
}
