
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.boolean {
  :global(.MuiButtonBase-root.Mui-checked) {
    color: palette.$primary-main;
  }
}
