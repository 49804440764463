
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
@keyframes BLINK_BACKGROUND {
  0%,
  100% {
    background-color: transparent;
  }
   50% {
    background-color: #c8e6c9;
  }
}
