
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.menuPaper {
  width: 18.75rem;
}

.avatar {
  min-width: unset;
  margin-right: 0.5rem;
}

.header {
  height: 1.25rem;
  display: flex;
  align-items: center;
}

.emailText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.pickerContainer {
  margin-top: 0.75rem;
}

.logout {
  color: palette.$error-main;
}
