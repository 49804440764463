
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.dropzone {
  &__image {
    height: 15rem;
    width: 16rem;
    object-fit: cover;
  }
}

.container {
  height: 10rem;
}

.card {
  width: 15rem;
}
