
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.noteContent {
  & img {
    max-width: 30rem;
    @media screen and (max-width: 48em) {
      max-width: 100%;
    }
  }
}
