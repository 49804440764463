
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
.image__card {
  :global(.MuiImageList-root) {
    height: auto;
    width: auto;
  }

  :global(.MuiImageListItem-root) {
    height: variables.$space-image-height;
    width: variables.$space-image-width;
  }

  :global(.MuiImageListItem-img) {
    height: calc(#{variables.$space-image-height} - 1.25rem);
    width: calc(#{variables.$space-image-width} - 1.25rem);
    grid-template-columns: repeat(4, 1fr);
  }

  :global(.MuiImageListItemBar-root) {
    width: calc(#{variables.$space-image-width} - 1.25rem);
  }
}

.uploadImage {
  border: 0.063rem solid rgba(0, 0, 0, 0.23);
  border-radius: 0.25rem;
  height: fit-content;
}

.itemBarIcon {
  color: rgba(255, 255, 255, 0.54);
}
