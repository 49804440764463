// Common ------------------------------
$white: #ffffff;
$black: #000000;
$blue-grey-50: rgba(236, 239, 241, 1);

// Grey ------------------------------
$grey-0: #ffffff;
$grey-100: #f9fafb;
$grey-200: #f4f6f8;
$grey-300: #dfe3e8;
$grey-400: #c4cdd5;
$grey-500: #919eab;
$grey-600: #637381;
$grey-700: #454f5b;
$grey-800: #212b36;
$grey-900: #161c24;

// Action ------------------------------
$hover: rgba($grey-500, 0.08);
$selected: rgba($grey-500, 0.16);
$disabled: rgba($grey-500, 0.8);
$disabledBackground: rgba($grey-500, 0.24);
$focus: rgba($grey-500, 0.24);

// Primary ------------------------------
$primary-lighter: #d1e9fc;
$primary-light: #76b0f1;
$primary-main: #2065d1;
$primary-dark: #103996;
$primary-darker: #061b64;
$primary-contrast-text: #ffffff;

// Secondary ------------------------------
$secondary-lighter: #efd6ff;
$secondary-light: #c684ff;
$secondary-main: #8e33ff;
$secondary-dark: #5119b7;
$secondary-darker: #27097a;
$secondary-contrast-text: #ffffff;

// Success ------------------------------
$success-lighter: #d3fcd2;
$success-light: #77ed8b;
$success-main: #22c55e;
$success-dark: #118d57;
$success-darker: #065e49;
$success-contrast-text: #ffffff;

// Warning ------------------------------
$warning-lighter: #fff5cc;
$warning-light: #ffd666;
$warning-main: #ffab00;
$warning-dark: #b76e00;
$warning-darker: #7a4100;
$warning-contrast-text: $grey-800;

// Error ------------------------------
$error-lighter: #ffe9d5;
$error-light: #ffac82;
$error-main: #ff5630;
$error-dark: #b71d18;
$error-darker: #7a0916;
$error-contrast-text: #ffffff;

// Info ------------------------------
$info-lighter: #cafdf5;
$info-light: #61f3f3;
$info-main: #00b8d9;
$info-dark: #006c9c;
$info-darker: #003768;
$info-contrast-text: #ffffff;

// -- Custom Colors
$et-blue-50: rgba(235, 241, 255, 1);
$et-blue-100: rgba(191, 210, 255, 1);
$pink-100: rgba(248, 187, 208, 1);
$pink-50: rgba(252, 228, 236, 1);
$teal-50: rgb(178, 223, 219, 1);
